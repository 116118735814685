.form-civilization-diseases__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 80px;
    padding-bottom: 80px;
}

.civilization-diseases__inner-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.civilization-diseases_label-input__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 16px;

    color: var(--light-gray);
    background: var(--dark-background);
    box-shadow: 0 12px 14px rgb(0, 0, 0, 0.1);
    border-radius: 20px;
    border: 2px solid var(--medium-light-gray);
    padding: 40px 30px;
}

.civilization-diseases_header__wrapper {
    width: 100%;
    font-size: 18px;
    display: flex;
    color: var(--white);
    justify-content: left;
    padding-bottom: 15px;
}
