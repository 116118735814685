.select-button_main {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    width: 100%;
    height: 35px;
    background: var(--medium-light-gray);
    color: var(--dark-background);
    border: 1px solid var(--dark-background);
    border-radius: 8px;
}
