.switch_main-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

button {
    all: unset;
}

.switch_root {
    width: 35px;
    height: 17px;
    position: relative;
    border: 1px solid var(--light-gray);
    border-radius: 20px;
    padding: 1px 0 1px 0;
}

.switch_root:hover {
    cursor: pointer;
}

.switch_root[data-state='checked'] {
    background-color: var(--orange);
    border: 1.2px solid var(--white);
}

.switch_thumb {
    display: block;
    width: 14px;
    height: 14px;
    background-color: var(--white);
    border-radius: 20px;
    transition: transform 400ms;
    transform: translateX(2px);
    will-change: transform;
}

.switch_thumb:hover {
    cursor: pointer;
}

.switch_thumb[data-state='checked'] {
    transform: translateX(19px);
}

.switch-label {
    color: white;
    font-size: 14px;
    line-height: 1;
}
