.main-screen__wrapper {
    width: 100vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;
}

.top-bar__wrapper {
    width: 100%;
    display: flex;
    background: var(--dark-background);
    color: var(--light-gray);
}

.menu-home__wrapper {
    width: 10%;
    display: flex;
    align-items: center;
    padding: 12px 12px;
}

.menu-home__wrapper:hover {
    color: var(--orange);
    cursor: pointer;
}

.menu-home__wrapper {
    width: 10%;
    display: flex;
    align-items: center;
    padding: 12px 12px;
}

.menu-text__wrapper {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 12px 0;
}

.menu-item__wrapper {
    display: flex;
    gap: 10px;
}
.menu-item__wrapper:hover {
    cursor: pointer;
    color: var(--orange);
}

.language-bar__wrapper {
    width: 10%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 10px;
    color: white;
    padding: 12px 0;
}

.flag__image {
    height: 14px;
    width: auto;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.flag__image:hover {
    transform: scale(1.05);
    border: 1px solid var(--orange);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.content__wrapper {
    width: 100%;
}
