.checkbox_main-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

button {
    all: unset;
}

.checkbox-root {
    width: 18px;
    height: 18px;
    border: 1px solid var(--dark-background);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.checkbox-root:hover {
    cursor: pointer;
}

.checkbox-root:disabled {
    cursor: auto;
    opacity: 20%;
}

.checkbox-root__light {
    width: 18px;
    height: 18px;
    border: 1px solid var(--light-gray);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox-indicator {
    color: var(--dark-background);
    background-color: var(--medium-light-gray);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
    width: 18px;
}

.checkbox-label {
    color: var(--dark-background);
    font-size: 14px;
    line-height: 1;
}

.checkbox-label__light {
    color: var(--light-gray);
    font-size: 14px;
    line-height: 1;
}

.checkbox-regulations-label {
    color: var(--dark-background);
    font-size: 11px;
    line-height: 1;
}

.checkbox_regulations-text {
    font-size: 11px;
    color: var(--dark-background);
    text-decoration: underline;
}

.checkbox_regulations-text:hover {
    color: var(--medium-light-gray);
}
