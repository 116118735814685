.form-birth-date__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 80px;
    padding-bottom: 80px;
}

.label-input__wrapper {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    color: var(--dark-background);
    padding: 40px 30px;
}

.question__wrapper {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    flex-direction: row;
    color: var(--light-gray);
    background: var(--dark-background);
    box-shadow: 0 12px 14px rgb(0, 0, 0, 0.1);
    border-radius: 20px;
    border: 2px solid var(--medium-light-gray);
    padding: 10px 20px;
}

.label__wrapper {
    width: 60%;
}

.input__wrapper {
    width: 40%;
}



/* ================== ant-picker css ================== */

.ant-picker {
    background-color: var(--very-light-gray) !important;
    border: 1px solid var(--dark-background);
    width: 100% !important;
}

.ant-picker:hover {
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid var(--dark-background);
}

.ant-picker-dropdown {
    background-color: transparent !important;
}

.ant-picker-panel {
    background-color: var(--very-light-gray) !important;
}

.ant-picker-cell-inner {
    background-color: transparent !important;
    color: var(--dark-background) !important;
    width: 100% !important;
}

.ant-picker-cell-today {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.ant-picker-cell-inner:hover {
    background-color: #DCDCDC !important;
    color: var(--dark-background) !important;
    width: 100% !important;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: var(--dark-background) !important;
    color: var(--white) !important;
}

/* outline around input was blue x_X */
div .ant-picker {
    border-color: var(--dark-background) !important;
    box-shadow: none !important;
}
/* ================== ant-picker css ================== */
