.medical-license-number-input {
    width: 180px;
    max-width: 180px;
    text-align: center;
    font-size: 20px;
    background: none;
    height: 22px;
    border-radius: 8px;
    border: 1px solid var(--dark-background);
    background: var(--medium-light-gray);
    color: var(--dark-background);
    padding: 5px 10px;
    text-transform: uppercase;
}

.medical-license-number-input:focus {
    outline: none;
}

.medical-license-number-input::placeholder {
    color: var(--dark-background);
}
